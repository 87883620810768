import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import type { Router, Navigation } from '@toolpad/core';
import CustomerList from './Components/Customer/CustomerList';
import AuthenticationList from './Components/Authentication/AuthenticationList';
import MachineList from './Components/Machines/MachinesList';
import InvoiceList from './Components/Invoices/InvoiceList';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import HashList from './Components/Monitor/HashList';


const NAVIGATION: Navigation = [
    // {
    //     kind: 'header',
    //     title: 'Main items',
    // },
    {
        segment: 'customers',
        title: 'Customers',
        icon: <GroupIcon />,
    },
    {
        segment: 'machines',
        title: 'Machines',
        icon: <MicrowaveIcon />,
    },
    {
        segment: 'invoices',
        title: 'Invoices',
        icon: <DescriptionIcon />,
    },
    {
        segment: 'authentication',
        title: 'Authentication',
        icon: <DomainVerificationIcon />,
    },
    {
        segment: 'monitor',
        title: 'Hash Monitor',
        icon: <MonitorHeartIcon/>,

    }

    // {
    //     kind: 'divider',
    // },
    // {
    //     kind: 'header',
    //     title: 'Analytics',
    // },
    // {
    //     segment: 'reports',
    //     title: 'Reports',
    //     icon: <BarChartIcon />,
    //     children: [
    //         {
    //             segment: 'sales',
    //             title: 'Sales',
    //             icon: <DescriptionIcon />,
    //         },
    //         {
    //             segment: 'traffic',
    //             title: 'Traffic',
    //             icon: <DescriptionIcon />,
    //         },
    //     ],
    // },
    // {
    //     segment: 'integrations',
    //     title: 'Integrations',
    //     icon: <LayersIcon />,
    // },
];

const demoTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});


interface DemoProps {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
}

export default function DashboardLayoutBasic(props: DemoProps) {
    const { window } = props;

    const [pathname, setPathname] = React.useState('/customers');

    const router = React.useMemo<Router>(() => {
        return {
            pathname,
            searchParams: new URLSearchParams(),
            navigate: (path) => setPathname(String(path)),
        };
    }, [pathname]);




    // Remove this const when copying and pasting into your project.
    const demoWindow = window !== undefined ? window() : undefined;

    return (
        <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={demoTheme}
            window={demoWindow}
        >
            <DashboardLayout>
                <React.Suspense fallback={<div>Loading...</div>}>
                    {pathname === '/customers' && <CustomerList />}
                    {pathname === '/machines' && <MachineList />}
                    {pathname === '/authentication' && <AuthenticationList />}
                    {pathname === '/invoices' && <InvoiceList />}
                    {pathname==='/monitor' && <HashList/> }
                </React.Suspense>
            </DashboardLayout>
        </AppProvider>
    );
}
