import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { appContext } from '../App';
import DashboardLayoutBasic from './LandingPage';

interface revalidate {
  validate: () => void
}

function MainView(props: revalidate) {
  const [isAuthVerified, setIsAuthverified] = useState<boolean>(true);
  useEffect(() => {
    props.validate()
  }, [isAuthVerified])
  return (
    <appContext.Provider value={{
      setIsAuthverified: setIsAuthverified,
    }} >
      <Routes>
        <Route path="/" element={<DashboardLayoutBasic />} />
      </Routes>
    </appContext.Provider >
  );
}

export default MainView;
