// InvoiceList.tsx
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import InvoiceModal from './InvoiceModal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { Invoice } from './types';
import TableLoader from '../Loaders/TableLoader';



const InvoiceList = () => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<number | ''>('');


    const [invoicesList, setInvoicesList] = useState<Invoice[]>([]);
    const [totalItemCount, setTotalItemCount] = useState<number>(0);
    const [pageableData, setPageableData] = useState<{ pageNumber: number; pageSize: number } | null>(null);
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        fetchInvoiceList();
    }, [searchQuery, page, rowsPerPage]);

    const handleRowClick = (invoice: Invoice) => {
        setSelectedInvoice(invoice);
        setIsAdding(false);
        setSelectedCustomer(invoice.id);
    };

    const fetchInvoiceList = async () => {
        setLoading(true);
        axios.get(`${API_PATH}/invoice/list?key=${searchQuery}&page=${page}&limit=${rowsPerPage}`, getAxiosConfig())
            .then(response => {
                setInvoicesList(response.data.content);
                setTotalItemCount(response.data.totalElements);
                setPageableData({
                    pageNumber: response.data.pageable.pageNumber,
                    pageSize: response.data.pageable.pageSize,
                });
            })
            .catch(error => {
                console.error("Error fetching invoice list:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const handleAddClick = () => {
        setSelectedInvoice(null);
        setIsAdding(true);
    };

    const handleCloseModal = () => {
        setSelectedInvoice(null);
        setIsAdding(false);
    };



    function formatDateToDDMMYYYY(isoString: string): string {
        const date = new Date(isoString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }


    return (
        <Box sx={{ flexGrow: 1, p: 2, position: 'relative', height: 'calc(100vh - 64px)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Invoice List
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ width: '300px', mr: 2 }}
                    />
                    <IconButton
                        color="primary"
                        aria-label="add"
                        onClick={handleAddClick}
                        sx={{
                            width: 40,
                            height: 40,
                            backgroundColor: 'primary.main',
                            color: 'white',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                opacity: 1,
                            },
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    {loading ? (
                        <TableLoader />
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Total Price</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Note</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoicesList.map((invoice) => (
                                        <TableRow key={invoice.id} onClick={() => handleRowClick(invoice)} style={{ cursor: 'pointer' }}>
                                            <TableCell>{invoice.reference}</TableCell>
                                            <TableCell>{formatDateToDDMMYYYY(invoice.invoiceDate)}</TableCell>
                                            <TableCell>{invoice.totalPrice}</TableCell>
                                            <TableCell>{invoice.status}</TableCell>
                                            <TableCell>{invoice.note}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>

                <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, bgcolor: 'background.paper', p: 2 }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItemCount}
                        rowsPerPage={pageableData?.pageSize || 10}
                        page={pageableData?.pageNumber || 0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>

            <InvoiceModal
                open={Boolean(selectedInvoice || isAdding)}
                isAdding={isAdding}
                onClose={handleCloseModal}
                selectedInvoice={selectedInvoice}
                setSelectedInvoice={setSelectedInvoice}
            />
        </Box>
    );
};

export default InvoiceList;
