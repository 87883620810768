import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Tab, Typography } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import AddCustomerModal from './AddCustomerModal';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ConfirmationModal from './ConfirmationModal';
import { API_PATH } from '../../../App';
import TableLoader from '../Loaders/TableLoader';

interface Customer {
    id: number | null;
    customerGroupName: string;
    number:string;
    country: string;
    isActive: boolean;
}


export default function CustomerList() {
    const [rows, setRows] = useState<Customer[]>([]);
    const [countryList, setCountryList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchCustomerList();
        fetchCountryList();
    }, []);

    const fetchCountryList = () => {
        axios.get(API_PATH + '/customer/countries', getAxiosConfig())
            .then(response => {
                setCountryList(response.data);
            })
            .catch(error => {
                console.error("Error fetching country list:", error);
            });
    };

    const fetchCustomerList = () => {
        axios.get(API_PATH + '/customer/list?orderBy=none', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedCustomer(null);
    };

    const handleSubmit = (data: { id: number | null; customerGroupName: string; country: string; isActive: boolean }) => {
        setLoading(true);
        axios.post(API_PATH + '/customer/save', data, getAxiosConfig())
            .then(response => {
                console.log(response);
                fetchCustomerList();
                setLoading(false);
            });
    };

    const handleEdit = (customer: Customer) => {
        setSelectedCustomer(customer);
        handleOpenModal();
    };

    const handleOpenConfirmationModal = (customer: Customer) => {
        setSelectedCustomer(customer);
        setConfirmationModalOpen(true);
    };

    const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

    const handleDisableCustomer = () => {
        if (selectedCustomer) {
            const updatedCustomer = {
                ...selectedCustomer,
                isActive: selectedCustomer.isActive ? false : true,
            };
            handleSubmit(updatedCustomer);

            setConfirmationModalOpen(false);
        }
    };



    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Customer List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader/>
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customer table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Customer Group Name</TableCell>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell>Country</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.customerGroupName}
                                            </TableCell>
                                            <TableCell>{row.number}</TableCell>
                                            <TableCell>{row.country}</TableCell>
                                            <TableCell>{row.isActive ? 'Active' : 'Inactive'}</TableCell>
                                            <TableCell>
                                                <EditIcon
                                                    onClick={() => handleEdit(row)}
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                                {row.isActive ?
                                                    <BlockIcon
                                                        onClick={() => handleOpenConfirmationModal(row)}
                                                        sx={{ cursor: 'pointer', marginLeft: 2 }}
                                                    />
                                                    :
                                                    <CheckCircleOutlineIcon
                                                        onClick={() => handleOpenConfirmationModal(row)}
                                                        sx={{ cursor: 'pointer', marginLeft: 2 }}
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Button variant="contained" onClick={handleOpenModal}>
                        Add Customer
                    </Button>
                </Grid>
            </Grid>
            <AddCustomerModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
                countryList={countryList}
                selectedData={selectedCustomer}
            />
            <ConfirmationModal
                open={confirmationModalOpen}
                handleClose={handleCloseConfirmationModal}
                handleConfirm={handleDisableCustomer}
                customerName={selectedCustomer?.customerGroupName || ''}
                action={selectedCustomer?.isActive ? "Disable" : "Enable"}
            />
        </Box>
    );
}

