import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface ConfirmationModalProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    customerName: string;
    action:string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, handleClose, handleConfirm, customerName, action }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Disable Customer
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to {action} the customer <strong>{customerName}</strong>?
                </Typography>
                <Box mt={3} display="flex" justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Yes, {action}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
