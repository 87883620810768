import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import axios from 'axios';
import AddAuthenticationModal from './AddAuthenticationModal';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import TableLoader from '../Loaders/TableLoader';

interface Customer {
    id: number | null;
    userName: string;
    password: string;
    authRole: string;
    isActive: boolean;
    isLocked: boolean;
}

export default function AuthenticationList() {
    const [rows, setRows] = useState<Customer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
    const [confirmationType, setConfirmationType] = useState<string | null>(null);

    useEffect(() => {
        fetchAuthList();
    }, []);

    const fetchAuthList = () => {
        axios.get('/auth/list/', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, customer: Customer) => {
        setAnchorEl(event.currentTarget);
        setSelectedCustomer(customer);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        if (selectedCustomer) {
            setModalOpen(true);
            setAnchorEl(null);
        }
    };

    const handleDisableCustomer = () => {
        setConfirmationType('disable');
        setConfirmationOpen(true);
        setAnchorEl(null);
    };

    const handleUnlock = () => {
        setConfirmationType('unlock');
        setConfirmationOpen(true);
        setAnchorEl(null);
    };

    const handleConfirm = () => {
        if (selectedCustomer) {
            if (confirmationType === 'disable') {
                const updatedCustomer = {
                    ...selectedCustomer,
                    isActive: !selectedCustomer.isActive,
                };
                handleSubmit(updatedCustomer);
            } else if (confirmationType === 'unlock') {
                const updatedCustomer = {
                    ...selectedCustomer,
                    isLocked: false,
                };
                handleSubmit(updatedCustomer);
            }
        }
        setConfirmationOpen(false);
    };

    const handleCancel = () => {
        setConfirmationOpen(false);
        setConfirmationType(null);
    };

    const handleSubmit = (data: Customer) => {
        setLoading(true);
        axios.post('/auth/save/', data, getAxiosConfig())
            .then(response => {
                fetchAuthList();
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                console.error("Error saving customer data:", error);
                setError("Failed to save data.");
                setLoading(false);
            });
    };

    const handleOpenModal = () => setModalOpen(true);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedCustomer(null);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Authentication
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader/>
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customer table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Username</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.userName}
                                            </TableCell>
                                            <TableCell>{row.authRole}</TableCell>
                                            <TableCell>{row.isActive ? 'Active' : 'Inactive'}</TableCell>
                                            <TableCell>
                                                <Button
                                                    aria-controls={Boolean(anchorEl) ? 'options-menu' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenuClick(e, row)}
                                                >
                                                    <MoreHorizIcon sx={{ marginRight: 1 }} />
                                                </Button>

                                                <Menu
                                                    id="options-menu"
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem onClick={handleEdit}>
                                                        <EditIcon sx={{ marginRight: 1 }} /> Edit
                                                    </MenuItem>
                                                    <MenuItem onClick={handleDisableCustomer}>
                                                        {row.isActive ? (
                                                            <>
                                                                <BlockIcon sx={{ marginRight: 1 }} /> Disable
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CheckCircleOutlineIcon sx={{ marginRight: 1 }} /> Enable
                                                            </>
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem onClick={handleUnlock}>
                                                        <LockOpenIcon sx={{ marginRight: 1 }} /> Unlock
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Button variant="contained" onClick={handleOpenModal}>
                        Add Authentication
                    </Button>
                </Grid>
            </Grid>
            <AddAuthenticationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
                selectedData={selectedCustomer}
            />

            <Dialog
                open={confirmationOpen}
                onClose={handleCancel}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">
                    {confirmationType === 'disable' ? (selectedCustomer?.isActive ? 'Disable User' : 'Enable User') : 'Unlock User'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {confirmationType === 'disable'
                            ? `Are you sure you want to ${selectedCustomer?.isActive ? 'disable' : 'enable'} this user?`
                            : 'Are you sure you want to unlock this user?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
