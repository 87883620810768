import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Select, MenuItem, IconButton, Tooltip, Typography, Divider, SelectChangeEvent, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MachineDetail } from './types';


interface MachineDetailsTableProps {
    machineDetails: MachineDetail[];
    machineList: { id: number, modelName: string }[];
    note: string;
    vatChecked: boolean;
    handleModelSelectChange: (index: number) => (event: SelectChangeEvent<string>) => void;
    handleMachineChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleDeleteMachine: (index: number) => () => void;
    handleAddMachine: () => void;
    handleNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    calculateTotalPrice: () => number;
}

const MachineDetailsTable: React.FC<MachineDetailsTableProps> = ({
    machineDetails,
    machineList,
    note,
    vatChecked,
    handleModelSelectChange,
    handleMachineChange,
    handleDeleteMachine,
    handleAddMachine,
    handleNoteChange,
    calculateTotalPrice
}) => {
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Machine Model</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Hosting Charge</TableCell>
                        <TableCell>Plugin Days</TableCell>
                        <TableCell>Warranty</TableCell>
                        <TableCell>Insurance</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {machineDetails.map((detail, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Select
                                    value={detail.selectedModel ? JSON.stringify(detail.selectedModel) : ''}
                                    onChange={handleModelSelectChange(index)}
                                    displayEmpty
                                    name="model"
                                    size="small"
                                >
                                    <MenuItem value="">Select Model</MenuItem>
                                    {machineList.map(machine => (
                                        <MenuItem key={machine.id} value={JSON.stringify(machine)}>
                                            {machine.modelName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>

                            <TableCell>
                                <TextField
                                    name="price"
                                    variant="outlined"
                                    size="small"
                                    value={detail.price === 0 ? '' : detail.price}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>

                            <TableCell>
                                <TextField
                                    name="quantity"
                                    variant="outlined"
                                    size="small"
                                    value={detail.quantity === 0 ? '' : detail.quantity}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    name="hostingCharge"
                                    variant="outlined"
                                    size="small"
                                    value={detail.hostingCharge || ''}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    name="pluginDays"
                                    variant="outlined"
                                    size="small"
                                    value={detail.pluginDays || ''}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    name="warranty"
                                    variant="outlined"
                                    size="small"
                                    value={detail.warranty}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    name="insurance"
                                    variant="outlined"
                                    size="small"
                                    value={detail.insurance}
                                    onChange={handleMachineChange(index)}
                                />
                            </TableCell>
                            <TableCell>
                                <Tooltip title={`Price:${detail.selectedModel?.price}`} arrow>
                                    <TextField
                                        disabled
                                        variant="outlined"
                                        size="small"
                                        value={detail.quantity * detail.price}
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={handleDeleteMachine(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box sx={{ my: 2 }} textAlign="right">
                <Button variant="contained" onClick={handleAddMachine}>
                    +
                </Button>
            </Box>

            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                    <TextField
                        label="Note"
                        maxRows={4}
                        value={note}
                        onChange={handleNoteChange}
                        size="small"
                        sx={{
                            mr: 2,
                            width: '80%',
                            '& .MuiInputBase-root': {
                                height: '100px',
                                alignItems: 'flex-start', 
                            },
                        }}
                        multiline
                    />
                </Grid>


                <Grid item xs={6}>
                    <Grid container direction="column" alignItems="flex-end">
                        <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'right' }}>
                            Total Price: ${calculateTotalPrice()}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'right' }}>
                            VAT Amount: ${vatChecked ? calculateTotalPrice() * 0.05 : 0}
                        </Typography>
                        <Box sx={{ width: '100%', height: 0, border: '1px solid #f2f2f2', mt: 2, mb: 2 }} />

                        <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'right' }}>
                            Grand Total: ${vatChecked ? calculateTotalPrice() * 1.05 : calculateTotalPrice()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MachineDetailsTable;
