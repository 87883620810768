import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface Customer {
    id: number | null;
    customerGroupName: string;
    number:string;
    country: string;
    isActive: boolean;
}

interface AddCustomerModalProps {
    open: boolean;
    handleClose: () => void;
    handleSubmit: (data: Customer ) => void;
    countryList:string[];
    selectedData: Customer | null
}



const AddCustomerModal: React.FC<AddCustomerModalProps> = ({ open, handleClose, handleSubmit, countryList, selectedData }) => {
    const [id,selectedId]=useState<number | null>(null)
    const [customerGroupName, setCustomerGroupName] = useState('');
    const [number,setNumber]=useState('')
    const [country, setCountry] = useState('');
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        if (selectedData) {
            selectedId(selectedData.id)
            setCustomerGroupName(selectedData.customerGroupName);
            setNumber(selectedData.number);
            setCountry(selectedData.country);
            setIsActive(selectedData.isActive);
        }
    }, [selectedData]);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const data = {
            id: id,
            customerGroupName,
            number,
            country,
            isActive,
        };
        handleSubmit(data);

        handleClose();
        setCustomerGroupName('');
        setNumber('')
        setCountry('');
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {selectedData ? 'Edit Customer' : 'Add Customer'}
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Customer Group Name"
                        variant="outlined"
                        value={customerGroupName}
                        onChange={(e) => setCustomerGroupName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        variant="outlined"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel>Country</InputLabel>
                        <Select
                            value={country}
                            onChange={(e) => setCountry(e.target.value as string)}
                            label="Country"
                        >
                            {countryList.map((c, index) => (
                                <MenuItem key={index} value={c}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        Submit
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default AddCustomerModal;
