import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import AddMachineModal from './AddMachineModal';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import TableLoader from '../Loaders/TableLoader';

interface Machine {
    id: number | null;
    modelName: string;
    coin: {
        coin: string;
        algorithm: string;
    };
    hashRate: number;
    hashUnit: string;
    vendor: {
        id: number;
        vendorName: string;
    };
    isActive: boolean;
    price: number;
    power: number;
}


export default function MachineList() {
    const [rows, setRows] = useState<Machine[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchMachineList();
    }, []);

    const fetchMachineList = () => {
        axios.get(API_PATH + '/machine/list', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching machine list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, machine: Machine) => {
        setAnchorEl(event.currentTarget);
        setSelectedMachine(machine);
    };

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleEdit = () => {
    //     if (selectedMachine) {
    //         console.log(`Editing machine: ${selectedMachine.modelName}`);
    //         setModalOpen(true);
    //         setAnchorEl(null);
    //     }
    // };

    const handleSubmit = (save: Machine) => {
        const formattedData = {
            id: save.id,
            modelName: save.modelName,
            coin: save.coin.coin,
            hashRate: save.hashRate,
            hashUnit: save.hashUnit,
            vendor: save.vendor.id,
            isActive: save.isActive,
            price: save.price,
            power: save.power,
        };

        console.log(formattedData);

        setLoading(true);
        axios.post(API_PATH + '/machine/save', formattedData, getAxiosConfig())
            .then(response => {
                console.log(response);
                fetchMachineList();
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                console.error("Error saving machine data:", error);
                setError("Failed to save data.");
                setLoading(false);
            });
    };


    const handleOpenModal = () => setModalOpen(true);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedMachine(null);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Machine List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="machine table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Model Name</TableCell>
                                        <TableCell>Vendor</TableCell>
                                        <TableCell>Coin</TableCell>
                                        <TableCell>Algorithm</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Power (KW)</TableCell>
                                        <TableCell>Hash Rate</TableCell>
                                        <TableCell>Hash Unit</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.modelName}
                                            </TableCell>
                                            <TableCell>{row.vendor.vendorName}</TableCell>
                                            <TableCell>{row.coin.coin}</TableCell>
                                            <TableCell>{row.coin.algorithm}</TableCell>
                                            <TableCell>{row.price / 100}</TableCell>
                                            <TableCell>{row.power}</TableCell>
                                            <TableCell>{row.hashRate}</TableCell>
                                            <TableCell>{row.hashUnit}</TableCell>
                                            {/* <TableCell>
                                                <Button
                                                    aria-controls={Boolean(anchorEl) ? 'options-menu' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenuClick(e, row)}
                                                >
                                                    <MoreHorizIcon sx={{ marginRight: 1 }} />
                                                </Button>

                                                <Menu
                                                    id="options-menu"
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem onClick={handleEdit}>
                                                        <EditIcon sx={{ marginRight: 1 }} /> Edit
                                                    </MenuItem>

                                                </Menu>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Button variant="contained" onClick={handleOpenModal}>
                        Add Machine
                    </Button>
                </Grid>
            </Grid>
            <AddMachineModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
                selectedData={selectedMachine}
            />
        </Box>
    );
}
